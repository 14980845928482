import React, { useState, Fragment, useContext } from "react"
import { Formik, Form } from "formik"

import Layout from "layout/Layout"
import Section from "elements/Section"
import Message from "elements/Message"
import Container from "layout/Container"
import ActionButtons from "elements/ActionButtons"

import { formInitialValues } from "./utils/profileFormSchema"
import { handleProfileEditSubmit } from "./services/profile"
import { getSignedInUser } from "../Auth/services/user"
import { AppContext } from "../../context/AppContext"
import { navigate } from "gatsby"

import { generateFormField } from "elements/Form/services/form"
import { useMentalHealthFormFields } from "./hooks/useAirtableMHFormFields"

import DeleteAddressModal from "./Addresses/DeleteAddressModal"

const ProfileForm = (props) => {
  const { state, dispatch } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({})

  let { pageContext, location } = props
  let { backPath, module } = pageContext

  let { sectionFormFields, validationSchema } = useMentalHealthFormFields({
    formFields: pageContext.formFields,
  })

  let forceReset = location?.state?.forceReset

  let userData = {}
  if (module.name === "edit") userData = getSignedInUser()?.userData

  const handleSubmit = (values, { resetForm }) => {
    setLoading(true)

    handleProfileEditSubmit({
      values,
      resetForm,
      setMessage,
      setLoading,
      userData,
      dispatch,
      module: module.name,
      backRoute: location?.state?.backRoute,
    })
  }

  const handleDeleteAddress = ({ address, index }) => {
    dispatch({
      type: "SHOW_MODAL",
      payload: {
        heading: "Delete Address",
        isCard: true,
        headerClass: `has-text-danger has-background-danger-light has-text-weight-bold is-size-5`,
        content: (
          <DeleteAddressModal
            address={address}
            index={index}
            handleDeleteCallback={() => {
              navigate("/profile")
            }}
          />
        ),
      },
    })
  }
  let initialValues = formInitialValues(
    module.name,
    userData,
    location?.state?.address
  )

  return (
    <Layout
      title={module.title}
      seoTitle={module.seoTitle}
      subtitle={module.subtitle}
      isPrivate
      isPatient
    >
      <Container isCentered>
        {forceReset && (
          <Message color="warning">
            For your account security, we require you to change your password.
          </Message>
        )}
        <Formik
          initialValues={{ ...initialValues }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue}) => (
            <Form>
              {sectionFormFields
                .sort((firstField, secondField) => {
                  return firstField.order - secondField.order
                })
                .map((section) => {
                  if (module.name !== "edit") {
                    return (
                      <Fragment>
                        {section?.fields.map((field) => {
                          if (!field?.referenceAnswer) {
                            return (
                              <Fragment>
                                {generateFormField({
                                  formFields: section?.fields,
                                  formField: field,
                                  values,
                                  setFieldValue,
                                })}
                                {!!field?.addDividerAfterField && (
                                  <hr className="has-background-light" />
                                )}
                              </Fragment>
                            )
                          }
                          return null
                        })}
                      </Fragment>
                    )
                  }

                  return (
                    <Section
                      title={section?.section}
                      subtitle={section?.subtitle || ""}
                      id={section?.sectionId || ""}
                    >
                      {section?.message && (
                        <Message color="light">{section?.message}</Message>
                      )}
                      {section?.fields.map((field) => {
                        if (!field?.referenceAnswer) {
                          return (
                            <Fragment>
                              {generateFormField({
                                formFields: section?.fields,
                                formField: field,
                                values,
                                setFieldValue,
                              })}
                              {!!field?.addDividerAfterField && (
                                <hr className="has-background-light" />
                              )}
                            </Fragment>
                          )
                        }
                        return null
                      })}
                    </Section>
                  )
                })}

              {module.name === "edit-address" && !values.address.primary && (
                <button
                  type="button"
                  className="button is-danger mb-2"
                  onClick={() => {
                    handleDeleteAddress({
                      index: location?.state?.index,
                      address: location?.state?.address,
                    })
                  }}
                >
                  Delete this address
                </button>
              )}
              {message?.content && (
                <Message color={message?.type}>
                  {message?.content?.message || message?.content}
                </Message>
              )}
              <ActionButtons
                back={{
                  label: "Cancel",
                  link: location?.state?.backRoute
                    ? location.state.backRoute
                    : backPath,
                }}
                submit={{
                  loading: loading,
                  label: module.cta,
                }}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default ProfileForm
