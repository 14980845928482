import { profileSchema } from "../utils/profileSchema"

let { parseFormField } = require("services/airtable")

export const useMentalHealthFormFields = ({ formFields }) => {
  return {
    sectionFormFields: parseFormField(formFields),
    validationSchema: profileSchema({ fields: formFields }),
  }
}
